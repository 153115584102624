import PasswordElement, { PasswordElementProps } from './password-element';
import { Path, useWatch, FieldValues } from 'react-hook-form';
export type PasswordRepeatElementProps<T extends FieldValues> = PasswordElementProps<T> & {
  passwordFieldName: Path<T>;
};
export default function PasswordRepeatElement<TFieldValues extends FieldValues>({
  passwordFieldName,
  ...rest
}: PasswordRepeatElementProps<TFieldValues>) {
  const pwValue = useWatch({
    name: passwordFieldName,
    control: rest.control
  });
  return <PasswordElement {...rest} validation={{
    validate: (value: string) => {
      return value === pwValue || 'Password should match';
    }
  }} data-sentry-element="PasswordElement" data-sentry-component="PasswordRepeatElement" data-sentry-source-file="password-repeat-element.tsx" />;
}