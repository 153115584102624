import { Control, Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, Checkbox, TextField, TextFieldProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
export type AutocompleteElementProps<F extends FieldValues, T, M extends boolean | undefined, D extends boolean | undefined> = {
  name: Path<F>;
  control?: Control<F>;
  options: T[];
  loading?: boolean;
  multiple?: M;
  matchId?: boolean;
  rules?: ControllerProps['rules'];
  required?: boolean;
  label?: TextFieldProps['label'];
  showCheckbox?: boolean;
  autocompleteProps?: Omit<AutocompleteProps<T, M, D, any>, 'name' | 'options' | 'loading' | 'renderInput'>;
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
  filterSelectedOptions?: boolean;
  limitTags?: number;
};
type AutoDefault = {
  id: string | number; // must keep id in case of keepObject
  label: string;
};
export default function AutocompleteElement<TFieldValues extends FieldValues>({
  textFieldProps,
  autocompleteProps,
  name,
  control,
  options,
  loading,
  showCheckbox,
  rules,
  required,
  multiple,
  matchId,
  label,
  filterSelectedOptions,
  limitTags = -1
}: AutocompleteElementProps<TFieldValues, AutoDefault | string | any, boolean | undefined, boolean | undefined>) {
  const validationRules: ControllerProps['rules'] = {
    ...rules,
    ...(required && {
      required: rules?.required || 'This field is required'
    })
  };
  return <Controller name={name} control={control as Control<FieldValues, any>} rules={validationRules} render={({
    field: {
      onChange,
      onBlur,
      value,
      ref,
      ...fieldRest
    },
    fieldState: {
      error
    }
  }) => {
    let currentValue = multiple ? value || [] : value || null;
    if (matchId) {
      currentValue = multiple ? (value || []).map((i: any) => options.find(j => (j.id || j) === i)) : options.find(i => (i.id || i) === value) || null;
    }
    return <Autocomplete {...autocompleteProps} value={currentValue} filterSelectedOptions={filterSelectedOptions} loading={loading} multiple={multiple} limitTags={limitTags} options={options} disableCloseOnSelect={typeof autocompleteProps?.disableCloseOnSelect === 'boolean' ? autocompleteProps.disableCloseOnSelect : !!multiple} isOptionEqualToValue={autocompleteProps?.isOptionEqualToValue ? autocompleteProps.isOptionEqualToValue : (option, value) => {
      return value ? option.id === (value?.id || value) : false;
    }} getOptionLabel={autocompleteProps?.getOptionLabel ? autocompleteProps.getOptionLabel : option => {
      return `${option?.label || option?.name || option?.title || option}`;
    }} onChange={(event, value, reason, details) => {
      let changedVal = value;
      if (matchId) {
        changedVal = Array.isArray(value) ? value.map((i: any) => i?.id || i) : value?.id || value;
      }
      onChange(changedVal);
      if (autocompleteProps?.onChange) {
        autocompleteProps.onChange(event, value, reason, details);
      }
    }} renderOption={autocompleteProps?.renderOption ?? (showCheckbox ? (props, option, {
      selected
    }) => <li {...props}>
                      <Checkbox sx={{
        marginRight: 1
      }} checked={selected} />
                      {autocompleteProps?.getOptionLabel?.(option) || option.label || option}
                    </li> : undefined)} onBlur={event => {
      onBlur();
      if (typeof autocompleteProps?.onBlur === 'function') {
        autocompleteProps.onBlur(event);
      }
    }} renderInput={params => <TextField name={name} required={rules?.required ? true : required} label={label} {...textFieldProps} {...params} error={!!error} InputProps={{
      ...params.InputProps,
      endAdornment: <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>,
      ...textFieldProps?.InputProps
    }} inputProps={{
      ...params.inputProps,
      ...textFieldProps?.inputProps
    }} helperText={error ? error.message : textFieldProps?.helperText} inputRef={ref} />} {...fieldRest} />;
  }} data-sentry-element="Controller" data-sentry-component="AutocompleteElement" data-sentry-source-file="autocomplete-element.tsx" />;
}