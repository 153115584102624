import CloseIcon from '@mui/icons-material/Cancel';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { Checkbox, Chip, FormControl, FormControlProps, InputLabel, ListItemText, MenuItem, Select, SelectProps } from '@mui/material';
type OptionType = {
  id: number;
  name: string;
};
export type MultiSelectElementProps<T extends FieldValues> = Omit<SelectProps, 'value'> & {
  options: any;
  label?: string;
  required?: boolean;
  validation?: any;
  name: Path<T>;
  parseError?: (error: FieldError) => string;
  minWidth?: number;
  menuMaxHeight?: number;
  menuMaxWidth?: number;
  helperText?: string;
  showChips?: boolean;
  control?: Control<T>;
  showCheckbox?: boolean;
  formControlProps?: Omit<FormControlProps, 'fullWidth' | 'variant'>;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export default function MultiSelectElement<TFieldValues extends FieldValues>({
  options,
  label = '',
  required = false,
  validation = {},
  parseError,
  name,
  menuMaxHeight = ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  menuMaxWidth = 250,
  minWidth = 120,
  helperText,
  showChips,
  control,
  showCheckbox,
  formControlProps,
  ...rest
}: MultiSelectElementProps<TFieldValues>): JSX.Element {
  if (required && !validation.required) {
    validation.required = 'This field is required';
  }
  return <Controller name={name} rules={validation} control={control} render={({
    field: {
      value,
      onChange,
      onBlur
    },
    fieldState: {
      invalid,
      error
    }
  }) => {
    helperText = error ? typeof parseError === 'function' ? parseError(error) : error.message : helperText;
    return <FormControl {...formControlProps} style={{
      ...formControlProps?.style,
      minWidth
    }} variant={rest.variant} fullWidth={rest.fullWidth} error={invalid} size={rest.size}>
            {label && <InputLabel size={rest.size === 'small' ? 'small' : undefined} error={invalid} htmlFor={rest.id || `select-multi-select-${name}`} required={required}>
                {label}
              </InputLabel>}
            <Select {...rest} id={`select-multi-select-${name}`} multiple label={label || undefined} error={invalid} value={value || []} required={required} onChange={event => {
        const selectedValue = event.target.value as string;
        onChange(selectedValue);
      }} onBlur={onBlur} MenuProps={{
        ...rest.MenuProps,
        PaperProps: {
          ...(rest.MenuProps?.PaperProps ?? {
            style: {
              maxHeight: menuMaxHeight,
              width: menuMaxWidth,
              ...rest.MenuProps?.PaperProps?.style
            }
          })
        }
      }} renderValue={(selected: any) => {
        if (showChips) {
          return <div style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
                      {selected?.map((selectedId: number | string) => {
              const selectedOption = options?.find((option: any) => option.id === selectedId);
              return selectedOption ? <Chip key={selectedId} label={selectedOption.name} onDelete={() => {
                const newValue = value.filter((id: number) => id !== selectedId);
                onChange(newValue);
              }} deleteIcon={<CloseIcon onMouseDown={ev => {
                ev.stopPropagation();
              }} />} style={{
                margin: 2
              }} /> : null;
            })}
                    </div>;
        } else {
          return selected.map((selectedId: number | string) => {
            const selectedOption = options?.find((option: any) => option.id === selectedId);
            return selectedOption ? selectedOption.name : '';
          }).join(', ');
        }
      }}>
              {options?.map((item: any) => <MenuItem key={item.id} value={item.id || item.value || item.name || item} sx={{
          fontWeight: theme => Array.isArray(value) && value.includes(item.id) ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
        }}>
                  {showCheckbox && <Checkbox checked={Array.isArray(value) && value.includes(item.id)} />}
                  <ListItemText primary={item.name || item.label || item} style={{
            paddingLeft: showCheckbox ? 0 : 8
          }} />
                </MenuItem>)}
            </Select>
            {/* ...rest of the code */}
          </FormControl>;
  }} data-sentry-element="Controller" data-sentry-component="MultiSelectElement" data-sentry-source-file="multi-select-element.tsx" />;
}