import React, { forwardRef } from 'react';
import { Control, Path, FieldValues } from 'react-hook-form';
import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * SwitchElement Component
 *
 * A customizable switch component that integrates Material-UI with React Hook Form.
 * Provides a styled toggle switch with label and form control capabilities.
 *
 * Key Features:
 * - Material-UI integration
 * - React Hook Form compatibility
 * - Custom color options
 * - Flexible styling
 * - Accessible form controls
 *
 * @component
 * @example
 * ```tsx
 * <SwitchElement
 *   name="newsletterOptIn"
 *   label="Subscribe to newsletter"
 *   switchProps={{ color: 'primary' }}
 * />
 * ```
 */

/**
 * Defines the available color options for the switch component
 *
 * Maps to Material-UI's theme color palette, allowing consistent
 * styling across the application
 *
 * @typedef {string} SwitchColor
 */
type SwitchColor = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';

/**
 * Extended switch props that include custom color options
 *
 * Builds upon Material-UI's SwitchProps while replacing the
 * color prop with our custom SwitchColor type
 *
 * @typedef {Object} CustomSwitchProps
 * @extends {Omit<SwitchProps, 'color'>}
 * @property {SwitchColor} [color] - Custom color option for the switch
 */
type CustomSwitchProps = Omit<SwitchProps, 'color'> & {
  color?: SwitchColor;
};

/**
 * Props definition for the SwitchElement component
 *
 * Combines FormControlLabel props with additional properties needed
 * for React Hook Form integration and switch customization
 *
 * @typedef {Object} SwitchElementProps
 * @template T
 * @extends {Omit<FormControlLabelProps, 'control'>}
 * @property {Path<T>} name - Field name for React Hook Form integration
 * @property {Control<T>} [control] - React Hook Form control object
 * @property {CustomSwitchProps} [switchProps] - Props for the Switch component
 */
export type SwitchElementProps<T extends FieldValues> = Omit<FormControlLabelProps, 'control'> & {
  name: Path<T>;
  control?: Control<T>;
  switchProps?: CustomSwitchProps;
};

/**
 * Styled version of Material-UI's Switch component
 *
 * Applies custom styling to the base Switch component using
 * Material-UI's styled API, allowing for theme-aware customization
 *
 * @component
 * @param {Object} theme - Material-UI theme object
 * @returns {StyledComponent} Styled Switch component
 */
const StyledSwitch = styled(Switch)<SwitchProps>(({
  theme
}) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#FF792A',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

/**
 * Main SwitchElement component implementation
 *
 * A forwarded ref component that combines Material-UI's FormControlLabel
 * with a styled Switch component, providing form integration capabilities
 *
 * @component
 * @template T
 * @param {SwitchElementProps<T>} props - Component props
 * @param {React.Ref<HTMLButtonElement>} ref - Forwarded ref
 * @returns {JSX.Element} Rendered switch element
 *
 * Features:
 * - Forwards ref to underlying button element
 * - Integrates with React Hook Form
 * - Provides custom styling options
 * - Supports accessibility features
 * - Handles form control label positioning
 *
 * @example
 * ```tsx
 * const { control } = useForm();
 *
 * <SwitchElement
 *   name="settings.notifications"
 *   control={control}
 *   label="Enable Notifications"
 *   switchProps={{
 *     color: 'primary',
 *     disabled: false
 *   }}
 * />
 * ```
 */
const SwitchElement = forwardRef<HTMLButtonElement, SwitchElementProps<any>>(
/**
 * Internal component render function
 *
 * @param {SwitchElementProps<any>} props - Component props
 * @param {React.Ref<HTMLButtonElement>} ref - Forwarded ref
 * @returns {JSX.Element} Rendered form control with switch
 *
 * Props breakdown:
 * @property {Path<T>} name - Field name in the form
 * @property {Control<T>} control - Form control object
 * @property {string} label - Label text for the switch
 * @property {CustomSwitchProps} switchProps - Switch-specific props
 * @property {FormControlLabelProps} formControlLabelProps - Additional form control props
 */
(props, ref) => {
  const {
    name,
    control,
    label,
    switchProps,
    ...formControlLabelProps
  } = props;
  return <FormControlLabel control={<StyledSwitch ref={ref} {...switchProps} />} label={label} {...formControlLabelProps} />;
});

/**
 * Sets a display name for the component
 *
 * Useful for debugging and React DevTools identification
 *
 * @constant {string}
 */
SwitchElement.displayName = 'SwitchElement';
export default SwitchElement;